export const serviceLocation = [
    {
        link: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3807.186189973531!2d78.5721201!3d17.4028501!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd8e25d70a0e168e7!2sAshok%20Leyland%20dost%20showroom%20(%20dost%20plus)!5e0!3m2!1sen!2sin!4v1612954316753!5m2!1sen!2sin',
        location: 'Uppal',
        address: 'Plot No:2-41/A, Near Uppal Bus Stand, Warangal High Way,Uppal, Hyderabad - 500039',
        direction: 'https://goo.gl/maps/K26KAULkPpk5WMHb9'
    },
    {
        link: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d237.8555729126741!2d78.4238884!3d17.4745853!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb91e942685127%3A0x46fc84ccf7c371b5!2sAshok%20leyland%20partner!5e0!3m2!1sen!2sin!4v1612954475299!5m2!1sen!2sin',
        location: 'Kukkatpally',
        address: 'Pragathi Nagar, Moosapet, Hyderabad, Telangana 500072',
        direction: 'https://goo.gl/maps/dEbRWxH2N575Wccz7'
    },
    {
        link: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.7938165304186!2d78.53692481529941!3d17.469579905026553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9be7af40f3e3%3A0x3f6a0ac95826f32a!2sAshok%20Leyland%20Dost%20Saboo%20Autozone!5e0!3m2!1sen!2sin!4v1640835779400!5m2!1sen!2sin',
        location: 'LB Nagar',
        address: 'H.NO:66/4, Opp:Big bazar, Near kamineni Hospital Bypass Road, LB Nagar, Hyderabad - 500074',
        direction: 'https://goo.gl/maps/3dAmkwJ6sRQBkXUy8'
    },
    {
        link: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3808.2889338629348!2d78.41998481487579!3d17.349824388101077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9630053a5231%3A0xf111e0282550bf81!2sSaboo%20Autozone%20-%20Attapur!5e0!3m2!1sen!2sin!4v1612856750318!5m2!1sen!2sin',
        location: 'Attapur',
        address: 'H.NO: 4-25-5-1/3, Suleman Nagar, Opp: Fish Bulding, Pillar No:238, Ragendra Nager, Hyderabad,Telangana - 500052',
        direction: 'https://g.page/sabooautozoneattapur?share'
    },
    {
        link: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d475.82997205734335!2d78.6508153!3d17.4290666!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9f823260a3ef%3A0xd1bc3fdd08efae2b!2sAshok%20Leyland!5e0!3m2!1sen!2sin!4v1612954693892!5m2!1sen!2sin',
        location: 'Jodimetla',
        address: 'H,NO:2-150, SYR Gardens, Jodimetla, Ghatkesar, Warangal Hwy Road, Hyderabad, Telangana 501301',
        direction: 'https://g.page/sabooautozoneattapur?share'
    },
    {
        link: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1902.798538311027!2d78.58967975818585!3d17.47898880537102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9dc5f9a34407%3A0x7540d24d64e21028!2sAshok%20Leyland%20-%20Saboo%20AutoZone!5e0!3m2!1sen!2sin!4v1640776598812!5m2!1sen!2sin',
        location: 'Nagaram',
        address: 'H.No : 9-107, S.V. Nagar Opp : Indian Oil Petrol Bunk Nagaram, District, Medchal, Hyderabad, Telangana 500083',
        direction: 'https://goo.gl/maps/7sfABBKaaoKCjpmu8'
    },
    {
        link: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3820.368477111703!2d78.03937481529387!3d16.75833342505168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bca2f95bfb9bf31%3A0x4a948c7fbaf8aad0!2sAshok%20Leyland%20-%20Saboo%20AutoZone!5e0!3m2!1sen!2sin!4v1640775478143!5m2!1sen!2sin',
        location: 'Mahbubnagar',
        address: 'Plot no:7 , Survey no:11, near SVS hospital, Opp:indian oil petrol bunk, Yenugonda, Mahabub Nagar, Telangana 509001',
        direction: 'https://g.page/sabooautozonemahabubnagar?share'
    },
    {
        link: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3830.923096638781!2d77.8139674152899!3d16.22439283956942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc9fda07ec4eaa5%3A0x987a6a162ed40a5d!2sAshok%20Leyland%20Saboo%20AutoZone!5e0!3m2!1sen!2sin!4v1640775680321!5m2!1sen!2sin',
        location: 'Gadwal',
        address: 'Plot No:2L, P NO:50/91, Opp: JP Camp, Near Rural Police Station, Kurnool Road,Jogulamba, Gadwal, Telangana 509125',
        direction: 'https://goo.gl/maps/E38dVFAcvnWCGr35A'
    },
    {
        link: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3793.1659444032234!2d78.85382651530433!3d18.063870987695044!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcc935609194f1f%3A0x206852b18769da29!2sAshok%20Leyland%20-%20Saboo%20AutoZone!5e0!3m2!1sen!2sin!4v1640775974705!5m2!1sen!2sin',
        location: 'Siddipet',
        address: 'Plot No:11, Ponnal Villege Road, Siddipet, Telangana 502103',
        direction: 'https://g.page/sabooautozonesiddipet?share'
    },
    {
        link: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3802.762534256554!2d78.07937401487949!3d17.61400768794314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbf94bfcd864ed%3A0xe079f4296ee8adbb!2sSaboo%20Autozone%20-%20Sangareddy!5e0!3m2!1sen!2sin!4v1612857309876!5m2!1sen!2sin',
        location: 'Sangareddy',
        address: 'Sy No: 777/3, KANDI(V&M, Beside MAR Garden, Sangareddy, Telangana 502295',
        direction: 'https://goo.gl/maps/se2n38V4U4p8QYcP6'
    },
    {
        link: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3808.698957278067!2d77.91739641529826!3d17.33006820901626!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc95f327aa61561%3A0x6ccf99a4b9b0a808!2sAshok%20leyland!5e0!3m2!1sen!2sin!4v1640776221347!5m2!1sen!2sin',
        location: 'Vikarabad',
        address: 'H NO; 3-2-41/1 Yennepally Opp. Mazeed, Hyderabad Rd, Vikarabad, Telangana 501101',
        direction: 'https://goo.gl/maps/xoecyorMQiAFWWyH8'
    },
]
